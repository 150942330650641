import { bindable } from 'aurelia-framework';
import { PLATFORM } from 'aurelia-pal';
import { Security } from 'common/security';
import { Leads } from 'services/leads';

export class AgentLeadCard {
    static inject = [Element, Security, Leads];
    _element;
    _leads;

    @bindable leadId;
    lead = null;
    tab;
    tabView = '';
    tabViewModel = null;

    tabs = [
        { key: 'activity', title: 'lead:activity', viewModel: PLATFORM.moduleName('./tabs/tab-activity'), display: true },
        { key: 'notes', title: 'notes', viewModel: PLATFORM.moduleName('./tabs/tab-notes'), display: true },
    ];

	constructor(element, security, leads) {
        this._element = element;
		this.security = security;
        this._leads = leads;
	}

    activate(model) {
        const activateTab = model.tab
            ? typeof model.tab === 'string' ? model.tab : model.tab.key || this.tabs[0].key
            : this.tabs[0].key;
        this.tab = activateTab ? this.tabs.find(x => x.key === activateTab) || this.tabs[0] : this.tabs[0];
        this.leadId = model.id;
    }

    attached() {
        this._isAttached = true;
        this._load();
    }

    leadIdChanged() {
        if (!this._isAttached) return;
        this._load();
    }

    async _load() {
        if (!this.leadId) return;
        try {
            this.loading = true;
    		this.lead = await this._leads.byId(this.leadId);
            this._element.dispatchEvent(new CustomEvent('loaded', { bubbles: true, detail: { name: this.lead.fullName } }));
            this.displayTab(this.tab);
		} catch (err) {
            console.log(err);
        } finally {
            this.loading = false;
        }
	}

	displayTab(tab) {
        this.tab = tab || this.tabs[0];
        this.tabModel = this.tabModel || {};
        this.tabViewModel = this.tab.viewModel;
	    switch (this.tab.key) {
            case 'notes':
                this.tabModel = { leadId: this.lead.id, security: this.security };
                break;
            case 'activity':
                this.tabModel = this.lead;
                break;
            default:
                this.tabModel = this.lead;
                break;
        }
	}
}
