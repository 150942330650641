export class TabScripts {
    leadId;

    constructor() {
    }

    activate(model) {
        this.leadId = model.id;
    }
}
