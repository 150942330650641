import { EventAggregator } from 'aurelia-event-aggregator';
import { DialogController } from 'aurelia-dialog';
import { Security } from 'common/security';
import { c } from 'common/common';

export class LeadCardDialog2 {
    static inject = [EventAggregator, DialogController, Security];
    _ea;
    dialogController;
    security;

    _handlers = [];

	constructor(ea, dialogController, security) {
		this._ea = ea;
        this.dialogController = dialogController;
        this.dialogController.settings.centerHorizontalOnly = true;
        this.security = security;
	}

    activate(model) {
        this.leadId = model.loaded ? model.lead.id : model.id;
        this._handlers.push(this._ea.subscribe(c.EventKeys.login.timedOut, () => this.dialogController.cancel()));
    }

    deactivate() {
        this._handlers.forEach(h => h.dispose());
        this._handlers = [];
    }

    close() {
        this._ea.publish(c.EventKeys.site.leadClosed, { leadId: this.leadId });
        this.dialogController.cancel();
    }
}
