import { bindable } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
import { I18n } from 'common/i18n';
import { Leads } from 'services/leads';
import { c } from 'common/common';

export class AgentLeadCardWidgets {
    static inject = [EventAggregator, I18n, Leads];
    _ea;
    _leads;

    @bindable lead;

    _handlers = [];

    constructor(ea, i18n, leads) {
        this._ea = ea;
        this._leads = leads;
    }

    attached() {
        this._handlers.push(this._ea.subscribe(c.EventKeys.lead.updated, (data) => this._onLeadUpdated(data)));
    }

    detached() {
        this._handlers.forEach(h => h.dispose());
        this._handlers = [];
    }

    _onLeadUpdated(data) {
        if (!this.lead.id === data.id) return;
        this.lead = data.lead;
    }
}