import { PLATFORM } from 'aurelia-pal';
import { bindable } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
import { Leads, LEAD_STATUS } from 'services/leads';
import { DialogService } from 'aurelia-dialog';
import { LeadStatusValueConverter } from 'resources/value-converters/lead-status';
import { LpfnUtil } from 'common/utils';

import { AgentLeadCardDialog } from './../dialogs/agent-lead-card-dialog';
PLATFORM.moduleName('./../dialogs/agent-lead-card-dialog');

export class AgentLeadList {
    static inject = [EventAggregator, Leads, DialogService, LeadStatusValueConverter];
    @bindable agentId;
    @bindable showKpis = true;
    @bindable ids;
    @bindable restrictToIds = false;
    @bindable lsKey = 'lpfn.lead-list.filters';
    _ea;
    _leads;
    _dialogService;
    _lsvc;

    pageSize = 10;
    DG_ORDERED_IDS_EVENT_KEY = 'lpfn-agent-lead-list-data-grid-ordered-ids';

    filters = [
        { key: 'search', value: '', keys: ['firstName', 'lastName', 'city', 'state', 'zipCode', 'county', 'email', 'type', 'source', 'statusName', 'dispositionName'] },
    ];
    filterColumns;

    _handlers = [];

    constructor(ea, leads, dialogService, lsvc) {
        this._ea = ea;
        this._leads = leads;
        this._dialogService = dialogService;
        this._lsvc = lsvc;
    }

    attached() {
        this._isAttached = true;
        this._handlers.push(this._ea.subscribe(this.DG_ORDERED_IDS_EVENT_KEY, (data) => this._onDataGridOrderedIds(data)));
        this._load();
    }

    detached() {
        this._handlers.forEach(h => h.dispose());
        this._handlers = [];
        this._leads.clearNav();
    }

    agentIdChanged() {
        if (!this.agentId || !this._isAttached) return;
        this._load();
    }

    idsChanged() {
        if (!this.agentId || !this._isAttached) return;
        this._load();
    }

    _onDataGridOrderedIds(orderedIds) {
        this._leads.setNavListIds(orderedIds);
    }

    async _load() {
        if (!this.agentId) return;
        if (this.restrictToIds && !this.ids) return;
        try {
            this.loading = true;
            this.leads = await this._leads.forAgent(this.agentId, this.ids);
            this._onDataGridOrderedIds(this.leads.map(x => x.id));
            this._initializeFilters();
        } catch (err) {
            console.log(err);
            this._leads.clearNav();
        } finally {
            this.loading = false;
        }
    }

    openLeadCard(lead) {
	    this._dialogService.open({ viewModel: AgentLeadCardDialog, model: lead, ignoreTransitions: true });
    }

    _initializeFilters() {
        try {
            const filterColumns = [];
            const defaultStatuses = LEAD_STATUS.defaults().map(x => this._lsvc.toView(x));
            const allStatuses = LEAD_STATUS.all().map(x => this._lsvc.toView(x));
            filterColumns.push({ key: 'statusName', labelKey: 'status', type: 'options', options: allStatuses, defaultValue: defaultStatuses.join('|') });
            filterColumns.push({ key: 'firstName', labelKey: 'first-name', type: 'string', defaultValue: undefined });
            filterColumns.push({ key: 'lastName', labelKey: 'last-name', type: 'string', defaultValue: undefined });
            filterColumns.push({ key: 'city', labelKey: 'city', type: 'string', defaultValue: undefined });
            filterColumns.push({ key: 'state', labelKey: 'state', type: 'options', options: LpfnUtil.uniqueArray(this.leads.map(x => x.state)), defaultValue: undefined });
            filterColumns.push({ key: 'county', labelKey: 'county', type: 'options', options: LpfnUtil.uniqueArray(this.leads.map(x => x.county)), defaultValue: undefined });
            filterColumns.push({ key: 'zipCode', labelKey: 'zip', type: 'string', allowMultiple: true, multipleDelimiter: ',', defaultValue: undefined });
            filterColumns.push({ key: 'dispositionName', labelKey: 'lead:disposition', type: 'options', options: LpfnUtil.uniqueArray(this.leads.map(x => x.dispositionName)), defaultValue: undefined });
            filterColumns.push({ key: 'assignedDate', labelKey: 'lead:assigned', type: 'date', initializeValue: '>|-7', defaultValue: undefined });
            filterColumns.push({ key: 'lastWorkedDate', labelKey: 'lead:last-worked-date', type: 'date', initializeValue: '>|-7', defaultValue: undefined });

            if (this.restrictToIds) {
                // clear out the default values
                filterColumns.forEach(fc => fc.defaultValue = undefined);
            }
            this.filterColumns = filterColumns;
        } catch (err) {
            console.log(err);
        }
    }

    filterColumnsSet(filters) {
        if (!filters) return;
        const newFilters = [];
        this.filters.forEach(f => newFilters.push(f));
        filters.forEach(f => newFilters.push(f));
        this.filters = newFilters;
    }

    toggleFilters() {
        this.showDrawer = !this.showDrawer;
    }

    gridFiltered(data) {
        this.filtered = data.filtered;
        this.filteredBy = data.filteredBy;
    }

    removeFilter(filter) {
        this.removeAdvancedFilter = filter;
    }

    viewLeadsFromKpi(leadIds) {
        if (leadIds === undefined) {
            this.viewingKpiLeads = false;
            this.leads = JSON.parse(JSON.stringify(this._displayedLeads));
            this._displayedLeads = undefined;
            this.restoreFilters = new Date().valueOf();
        } else {
            if (this.viewingKpiLeads) {
                // need to reset the list based on the original, not the current kpi list
                const originalLeads = JSON.parse(JSON.stringify(this._displayedLeads));
                this.leads = originalLeads.filter(x => leadIds.includes(x.id));
            } else {
                this.viewingKpiLeads = true;
                this._displayedLeads = JSON.parse(JSON.stringify(this.leads));
                this.leads = this.leads.filter(x => leadIds.includes(x.id));
                this.clearFilters = new Date().valueOf();
            }
        }
        this.reapplyDataGrid = new Date().valueOf();
    }
}
