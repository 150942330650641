import { EventAggregator } from 'aurelia-event-aggregator';
import { Router } from 'aurelia-router';
import { Leads } from 'services/leads';
import { c } from 'common/common';

export class Lead {
    static inject = [EventAggregator, Router, Leads];
    _ea;
    _router;
    _leads;

    constructor(ea, router, leads) {
        this._ea = ea;
        this._router = router;
        this._leads = leads;
    }

    async canActivate(model) {
        try {
            const canAccess = await this._leads.canAccess(model.id);
            if (!canAccess) {
                this._router.navigate('#/members/leads');
                return false;
            }
            return true;
        } catch (err) {
            console.log(err);
            return false;
        }
    }

    activate(model) {
        this.id = model.id;
        this.tab = model.view;
    }

    leadLoaded(data) {
        this._ea.publish(c.EventKeys.site.setPageTitle, { title: data.name });
    }
}