import { EventAggregator } from 'aurelia-event-aggregator';
import { DialogController } from 'aurelia-dialog';
import { I18n } from 'common/i18n';
import { c } from 'common/common';
import { Page, Notifier } from 'common/ui';
import { Security } from 'common/security';
import { Leads } from 'services/leads';
import { NewInstance } from 'aurelia-dependency-injection';
import { ValidationRules, ValidationController } from 'aurelia-validation';
import { BootstrapFormValidationRenderer } from 'validation/bootstrap-form-validation-renderer';

export class EditLeadDialog {
    static inject = [EventAggregator, DialogController, I18n, Page, Notifier, Security, Leads, NewInstance.of(ValidationController)];
	_ea;
	dialogController;
	_i18n;
	_page;
	_notifier;
    _security;
	_leads;
	validationController;

	firstName;
	lastName;
	email;
	phone;
	streetAddress;
	city;
	state;
	zip;
	county;

	usStates = [];

	constructor(eventAggregator, dialogController, i18n, page, notifier, security, leads, validationController) {
		this._ea = eventAggregator;
        this.dialogController = dialogController;
		this._i18n = i18n;
		this._page = page;
		this._notifier = notifier;
		this._security = security;
		this._leads = leads;
		this.validationController = validationController;
		this.validationController.addRenderer(new BootstrapFormValidationRenderer());

		this.usStates = c.Geography.usStates;

		ValidationRules
            .ensure('firstName').required()
	        .ensure('lastName').required()
            .on(this);
    }

	async activate(model) {
        this.leadId = model.id;
		this.firstName = model.firstName;
		this.lastName = model.lastName;
		this.email = model.email;
		this.phone = model.phone;
		this.streetAddress = model.address;
		this.city = model.city;
		this.state = model.state;
		this.zip = model.zipCode;
		this.county = model.county;
		this.title = `${this.firstName} ${this.lastName}`;
    }

	attached() {
		this._page.scrollToTop();
	}

	addressSelecting(selecting) {
		this._isSelectingAddress = selecting;
	}

	addressSelected(data) {
		console.log(data);
		this.streetAddress = data.streetAddress;
		this.city = data.city;
		this.state = data.state;
		this.zip = data.zip;
		this._isSelectingAddress = false;
	}

	async updateProfile() {
		if (this._isSelectingAddress) return;
		if (this.processing) return;

        try {
    		const v = await this.validationController.validate();
		    if (!v.valid) {
		        this._page.scrollToTop();
		        return;
		    }

		    this.processing = true;
			const payload = {
				firstName: this.firstName,
				lastName: this.lastName,
				email: this.email,
				phone: this.phone,
				streetAddress: this.streetAddress,
				city: this.city,
				state: this.state,
				zipCode: this.zip,
				county: this.county,
			}
			const updatedLead = await this._leads.updateProfile(this.leadId, payload);
			this._ea.publish(c.EventKeys.lead.profileUpdated, { lead: updatedLead });
			this.dialogController.ok();
        } catch (err) {
            console.log(err);
            if (typeof err === 'string') this.notifier.error(err);
            else this._notifier.generalError();
        } finally {
            this.processing = false;
        }
	}
}
