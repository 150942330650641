import { observable } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
import { Security } from 'common/security';
import { Leads } from 'services/leads';
import { Members } from 'services/members';
import { TIME_PERIOD } from 'common/constants';
import { WIDGET } from 'common/constants';

export class ActivityDashboard {
    static inject = [Element, EventAggregator, Security, Leads, Members];
    _element;
    _ea;
    _security;
    _leads;
    _members;

    kpiTimePeriods = TIME_PERIOD.common();
    kpiTimePeriod = TIME_PERIOD.default();
    @observable kpiCustomRange;

    kpiViews;
    kpiView;

    kpiGroupBys;
    kpiGroupBy;

    agentView = 'grid';
    filters = [{ value: '', keys: ['agent.fullName'] }];

    _handlers = [];

    constructor(element, ea, security, leads, members) {
        this._element = element;
        this._ea = ea;
        this._security = security;
        this._leads = leads;
        this._members = members;
        this.agent = this._security.agent;

        this.kpiTimePeriods = TIME_PERIOD.common();
        this.kpiTimePeriod = TIME_PERIOD.default();
    
        this.kpiViews = WIDGET.View.all(security, false, true);
        this.kpiView = WIDGET.View.default();

        this.kpiGroupBys = WIDGET.GroupBy.forAgents(security);
        this.kpiGroupBy = WIDGET.GroupBy.default();
    }

    attached() {
        this._isAttached = true;

        this.loadKpis();
    }

    detached() {
        this._handlers.forEach(h => h.dispose());
        this._handlers = [];
    }

    selectKpiView(view) {
        this.kpiView = view;
        this.loadKpis();
    }

    selectKpiGroupBy(groupBy) {
        this.kpiGroupBy = groupBy;
        this.loadKpis();
    }

    selectKpiTimePeriod(timePeriod) {
        this.kpiTimePeriod = timePeriod;
        if (this.kpiTimePeriod === 'custom') return;
        this._kpiStart = undefined;
        this._kpiEnd = undefined;
        this.loadKpis();
    }

    kpiCustomRangeChanged() {
        if (!this.kpiCustomRange || this.kpiCustomRange.length !== 2) return;
        this._kpiStart = this.kpiCustomRange[0].format('MM/DD/YYYY');
        this._kpiEnd = this.kpiCustomRange[1].format('MM/DD/YYYY');
        this.loadKpis(false);
    }

    async loadKpis() {
        try {
            this.loadingKpis = true;
            const kpiData = await this._leads.kpis(this.kpiTimePeriod, undefined, this._kpiStart, this._kpiEnd, 'lms', this.kpiView, this.kpiGroupBy);
            const kpiGroups = [];
            const groupCount = Math.ceil(kpiData.kpis.length / 4);
            for (let i = 0; i < groupCount; i++) kpiGroups.push({ expanded: i === 0, showExpander: i > 0, kpis: [] });
            this.drilldownAgents = [];
            var groupIndex = 0;
            kpiData.kpis.forEach(kpi => {
                kpi.canViewLeads = false;
                if (kpiGroups[groupIndex].kpis.length === 4) {
                    groupIndex++;
                }
                kpiGroups[groupIndex].kpis.push(kpi);
                kpi.drilldown.forEach(dd => {
                    if (this.drilldownAgents.findIndex(x => dd.agent.id === x.agent.id) >= 0) return;
                    this.drilldownAgents.push({ id: dd.agent.id, agent: dd.agent, kpiGroups: [] });
                });
            });
            this.kpiGroups = kpiGroups;
            this.drilldownAgents.forEach(a => {
                // find the individual kpis for this agent
                for (let j = 0; j < kpiGroups.length; j++) {
                    const ddKpiGroup = { expanded: kpiGroups[j].expanded, showExpander: kpiGroups[j].showExpander, kpis: [] };
                    for (let i = 0; i < kpiGroups[j].kpis.length; i++) {
                        let agentDrilldownKpi = kpiGroups[j].kpis[i].drilldown.find(x => x.agent.id === a.agent.id);
                        if (!agentDrilldownKpi) {
                            agentDrilldownKpi = JSON.parse(JSON.stringify(kpiGroups[j].kpis[i]));
                            delete agentDrilldownKpi.drilldown;
                            agentDrilldownKpi.increaseCountOverPreviousPeriod = 0;
                            agentDrilldownKpi.increasePercentOverPreviousPeriod = 0;
                            agentDrilldownKpi.leadIds = [];
                            agentDrilldownKpi.previousPeriodValue = 0;
                            agentDrilldownKpi.value = 0;
                        }
                        ddKpiGroup.kpis.push(agentDrilldownKpi);
                    }
                    a.kpiGroups.push(ddKpiGroup);
                }
            });
            this.progressPeriodName = kpiData.progressPeriodName;
            this.drilldownAgents.forEach(a => {
                a.kpi = {};
                a.kpi.dials = this._agentKpi(a.kpiGroups, 'total-dials');
                a.kpi.connects = this._agentKpi(a.kpiGroups, 'total-connects');
                a.kpi.appointmentsBooked = this._agentKpi(a.kpiGroups, 'appointments-booked');
                a.kpi.leadsReceived = this._agentKpi(a.kpiGroups, 'leads-received');
                a.kpi.dialsPerAppointment = this._agentKpi(a.kpiGroups, 'dial-appointment-ratio');
                a.kpi.connectsPerAppointment = this._agentKpi(a.kpiGroups, 'connect-appointment-ratio');
                a.kpi.appointmentsPerLead = this._agentKpi(a.kpiGroups, 'appointment-lead-ratio');
                a.kpi.dialsPerLead = this._agentKpi(a.kpiGroups, 'dials-per-lead');
            });
        } catch (err) {
            console.log(err);
        } finally {
            this.loadingKpis = false;
        }
    }

    _agentKpi(kpiGroups, kpiCode) {
        for (let kpiGroup of kpiGroups) {
            const kpi = kpiGroup.kpis.find(x => x.code === kpiCode);
            if (!kpi) continue;
            return { value: kpi.value, color: this.kpiColor(kpi.index)};
        }
        return { value: undefined };
    }

    kpiColor(index) {
        try {
            const colorIndex = index % 4;
            switch (colorIndex) {
                case 0: return 'lpfn-kpi-success';
                case 1: return 'lpfn-kpi-warning';
                case 2: return 'lpfn-kpi-info';
                case 3: return 'lpfn-kpi-danger';
                default: return 'lpfn-kpi-success'
            }
        } catch (err) {
            console.log(err);
            return 'lpfn-kpi-danger';
        }
    }

    showAgentView(agentView) {
        this.agentView = agentView;
    }

    viewAgentLeads(agent, kpi) {
        this.viewKpi = { agentId: agent.id, leadIds: kpi.leadIds };
        this.showLightbox = true;
        this.lightboxVM.open(false, true);
    }

    lightboxClosed() {
        this.viewKpi = undefined;
        this.showLightbox = false;
    }
}
