export class TabNotes {
    leadId;

    constructor() {
    }

    activate(model) {
        this.leadId = model.leadId;
        this.minimal = model.minimal;
    }
}
