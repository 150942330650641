import { DialogController } from 'aurelia-dialog';

export class PromptForDisposition {
    static inject = [Element, DialogController];
    _element;
    dialogController;

    leadId;
    dispositions;
    disposition;
    autoDialNextLead;

    constructor(element, dialogController) {
        this._element = element;
        this.dialogController = dialogController;
    }

    activate(model) {
        this.leadId = model.leadId;
        this.dispositions = model.dispositions;
        this.dialerTeam = model.dialerTeam;
        this.doNotCall = model.doNotCall;
        this.autoDialNextLead = model.autoDialNextLead;
        this.nextLeadName = model.nextLeadName;
    }

    select() {
        if (!this.disposition) {
            this.noDisposition();
            return;
        }
        this.dialogController.ok({ disposition: this.disposition, autoDialNextLead: this.autoDialNextLead });
    }

    noDisposition() {
        this.dialogController.ok({ disposition: undefined, autoDialNextLead: this.autoDialNextLead });
    }
}
