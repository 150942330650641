import { EventAggregator } from 'aurelia-event-aggregator';
import { DialogController } from 'aurelia-dialog';
import { Leads } from 'services/leads';
import { c } from 'common/common';

export class AgentLeadCardDialog {
    static inject = [EventAggregator, DialogController, Leads];

    leadName;

    _handlers = [];

	constructor(ea, dialogController, leads) {
		this._ea = ea;
        this.dialogController = dialogController;
        this.dialogController.settings.centerHorizontalOnly = true;
        this._leads = leads;
	}

    activate(model) {
        this.leadId = model.id;
        this._handlers.push(this._ea.subscribe(c.EventKeys.login.timedOut, () => this.dialogController.cancel()));
    }

    deactivate() {
        this._handlers.forEach(h => h.dispose());
        this._handlers = [];
    }

    attached() {
        this.nav = this._leads.getNav(this.leadId);
    }

    leadLoaded(data) {
        this.leadName = data.name;
    }

    navigateList(id) {
        this.leadId = id;
        this.nav = this._leads.getNav(this.leadId);
    }
}
