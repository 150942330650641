import { EventAggregator } from 'aurelia-event-aggregator';
import { I18n } from 'common/i18n';
import { Leads, LEAD_STATUS } from 'services/leads';
import { LeadStatusValueConverter } from 'resources/value-converters/lead-status';
import { LeadDispositionValueConverter } from 'resources/value-converters/lead-disposition';
import { c } from 'common/common';

export class TabActivity {
    static inject = [EventAggregator, I18n, Leads, LeadStatusValueConverter, LeadDispositionValueConverter];
    _ea;
    _i18n;
    _leads;
    _lsvc;
    _ldvc;
    lead;

    _handlers = [];

    constructor(ea, i18n, leads, lsvc, ldvc) {
        this._ea = ea;
        this._i18n = i18n;
        this._leads = leads;
        this._lsvc = lsvc;
        this._ldvc = ldvc;
    }

    activate(model) {
        this.leadId = model.lead ? model.lead.id : model.id;
        this.style = model.style || 'card';
        this._load();
    }

    attached() {
        this._handlers.push(this._ea.subscribe(c.EventKeys.lead.updated, () => this._load()));
        this._handlers.push(this._ea.subscribe(c.EventKeys.toDos.updated, () => this._load()));
    }

    detached() {
        this._handlers.forEach(h => h.dispose());
    }

    async _load() {
        try {
            this.loading = true;
            const allDispositions = this._leads.allDispositions();
            this.activities = [];
            this.activities = await this._leads.activities(this.leadId);
            let dialCount = 0;
            this.activities.forEach(a => {
                if (a.isDial) dialCount++;
                if (!a.metadata) a.metadata = JSON.parse(a.metadataJson);
                if (a.metadata.disposition) {
                    a.metadata.dispositionName = this._ldvc.toView(a.metadata.disposition);
                    const disposition = allDispositions.find(x => x.key === a.metadata.disposition);
                    if (disposition) {
                        a.metadata.dispositionIcon = disposition.icon;
                        a.metadata.dispositionIconClass = disposition.cssClass;
                    } else {
                        a.metadata.dispositionIcon = 'fa-strong fa-circle-question';
                    }
                }
                if (a.metadata.status) {
                    a.metadata.statusName = this._lsvc.toView(a.metadata.status);
                    a.metadata.statusIcon = LEAD_STATUS.icon(a.metadata.status);
                }
                if (a.type !== 'disposition') return;
                if (a.disposition) return;
                a.disposition = a.metadata.disposition;
            });

            const headerLeft = this._i18n.tr('lead:dial-count', { count: dialCount });
            this._ea.publish(c.EventKeys.lead.tabHeader, { key: 'activity', headerLeft });
        } catch (err) {
            console.log(err);
        } finally {
            this.loading = false;
        }
    }
}
