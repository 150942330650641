import { EventAggregator } from 'aurelia-event-aggregator';
import { I18n } from 'common/i18n';
import { Leads } from 'services/leads';
import { Security } from 'common/security';
import { c } from 'common/common';

export class TabAssignments {
    static inject = [EventAggregator, I18n, Leads, Security];
    _ea;
    _i18n;
    _leads;
    _security;

    assignments = [];

    constructor(ea, i18n, leads, security) {
        this._ea = ea;
        this._i18n = i18n;
        this._leads = leads;
        this._security = security;
    }

    activate(model) {
        this.lead = model;
        this._load();
    }

    async _load() {
        try {
            if (!this._security.isAdmin) return;
            this.assignments = await this._leads.assignments(this.lead.id);
            const headerLeft = this._i18n.tr('lead:assignment-count', { count: this.assignments.length });
            this._ea.publish(c.EventKeys.lead.tabHeader, { key: 'assignments', headerLeft });
        } catch (err) {
            console.log(err);
        }
    }

    viewLead(leadId) {
        this._ea.publish(c.EventKeys.site.openLead, { leadId });
    }
}
