import { DialogController } from 'aurelia-dialog';

export class SelectDialerTeamDialog {
    static inject = [Element, DialogController];
    _element;
    dialogController;

    dialerTeamKeys;
    dialerTeamKey;

    constructor(element, dialogController) {
        this._element = element;
        this.dialogController = dialogController;
    }

    activate(model) {
        this.dialerTeamKeys = model.dialerTeams;
        this.dialerTeam = '';
    }

    select() {
        if (!this.dialerTeamKey) return;
        this.dialogController.ok({ dialerTeam: this.dialerTeamKey });
    }
}
