import { bindable, computedFrom } from 'aurelia-framework';
import { PLATFORM } from 'aurelia-pal';
import { EventAggregator } from 'aurelia-event-aggregator';
import { Page } from 'common/ui';
import { Api } from 'common/server';
import { Security } from 'common/security';
import { Leads, LEAD_STATUS } from 'services/leads';
import { Members } from 'services/members';
import { Dialer } from 'services/dialer';
import { Notifier } from 'common/ui';
import { FilesFor } from 'services/files-for';
import { DialogService } from 'aurelia-dialog';
import { I18n } from 'common/i18n';
import { MemberConfig } from 'services/member-config';
import { c } from 'common/common';
import { ROLE, ABOUT_TYPE } from 'common/constants';
import { LpfnUtil } from 'common/utils';

import { EditNote } from '../../resources/elements/notes/dialogs/edit-note';
PLATFORM.moduleName('../../resources/elements/notes/dialogs/edit-note');
import { EditLeadDialog } from './dialogs/edit-lead-dialog';
PLATFORM.moduleName('./dialogs/edit-lead-dialog');
import { PromptForDisposition } from './dialogs/prompt-for-disposition';
PLATFORM.moduleName('./dialogs/prompt-for-disposition');

export class LeadCard2 {
    static inject = [Element, EventAggregator, Page, Api, Security, Leads, Members, Dialer, Notifier, FilesFor, DialogService, I18n, MemberConfig];
    _element;
    _leads;
    _members;
    _dialer;
    _notifier;
    _filesFor;
    _dialogService;
    _i18n;
    _memberConfig;

    @bindable leadId;
    @bindable tab;
    lead = null;
    tab;
    tabView = '';
    tabViewModel = null;

    leadCardSettings = {};

    tabs = [];
    rightTabs = [];

    displayMapView = 'place';
    enlargeAddressMap;

    _handlers = [];

	constructor(element, ea, page, api, security, leads, members, dialer, notifier, filesFor, dialogService, i18n, memberConfig) {
        this._element = element;
		this._ea = ea;
        this._page = page;
		this._api = api;
		this.security = security;
        this._leads = leads;
        this._members = members;
        this._dialer = dialer;
        this._notifier = notifier;
        this._filesFor = filesFor;
        this._dialogService = dialogService;
        this._i18n = i18n;
        this._memberConfig = memberConfig;

        this.leadCardSettings = this._memberConfig.json('lead-card') || {};
    }

    activate(model) {
        this.leadId = model.id;
    }

    async attached() {
        this._isAttached = true;
        await this._load();
        this._loadHandlers();

        this.nav = this._leads.getNavWithNames(this.leadId);
        this.activeTab = this.tabs.length ? this.tabs[0].key : undefined;
        this._initializeActions();
    }

    _loadHandlers() {
        this._handlers.push(this._ea.subscribe(c.EventKeys.lead.profileUpdated, () => {
            this.lead = undefined;
            this._load();
        }));
        this._handlers.push(this._ea.subscribe(c.EventKeys.lead.tabHeader, (data) => {
            const tab = this.tabs.find(x => x.key === data.key);
            if (!tab) return;
            tab.headerLeft = data.headerLeft;
        }));
        this._handlers.push(this._ea.subscribe('dnd:didEnd', async() => {
            window.setTimeout(() => this._saveOrdering(), 0);
        }));
        window.setTimeout(() => this._loadTabObservers(), 1000);
    }

    _saveOrdering() {
        const orderedKeys = this.tabs.map(x => x.key);
        this.leadCardSettings.tabOrder = orderedKeys;
        const rightOrderedKeys = this.rightTabs.map(x => x.key);
        this.leadCardSettings.rightTabOrder = rightOrderedKeys;
        this._saveSettings();
    }

    async _saveSettings() {
        try {
            await this._memberConfig.save('lead-card', JSON.stringify(this.leadCardSettings));
        } catch (err) {
            console.log(err);
        }
    }

    _initializeActions() {
        this.mapActions = [];
        this.mapActions.push({ key: 'map-default-street', name: this._i18n.tr('default-map-view-street'), icon: 'fa-duotone fa-street-view', cssClass: this.leadCardSettings.defaultMapView === 'street' ? 'lpfn-success' : '' });
        this.mapActions.push({ key: 'map-default-map', name: this._i18n.tr('default-map-view-map'), icon: 'fa-duotone fa-map-marker-alt', cssClass: this.leadCardSettings.defaultMapView === 'map' || !this.leadCardSettings.defaultMapView ? 'lpfn-success' : '' });
        this.mapActions.push({ key: 'map-default-zoom-plus', name: this._i18n.tr('default-map-zoom-plus'), icon: 'fa-duotone fa-magnifying-glass-plus' });
        this.mapActions.push({ key: 'map-default-zoom-minus', name: this._i18n.tr('default-map-zoom-minus'), icon: 'fa-duotone fa-magnifying-glass-minus' });
    }

    _loadTabObservers() {
        this.tabs.forEach(tab => {
            const tabEl = document.getElementById(`tab-${tab.key}`);
            if (!tabEl) return;
            this._handlers.push(LpfnUtil.inViewportHandler(tabEl, (isVisible, tab) => {
                tab.visible = isVisible;
                window.setTimeout(() => this._setActiveTab(), 0);
            }, tab));
        });
    }

    _setActiveTab() {
        // The first tab after the top not visible tab is the active tab
        if (this.tabs[0].visible) {
            this.activeTab = this.tabs[0].key;
            return;
        }
        for (let i = 1; i < this.tabs.length; i++) {
            if (!this.tabs[i].visible) continue;
            this.activeTab = this.tabs[i].key;
            return;
        }
    }

    detached() {
        this._clearHandlers();
    }

    _clearHandlers() {
        this._handlers.forEach(h => h.dispose());
        this._handlers = [];
    }

    navigateList(item) {
        if (!this.allDispositions.length) {
            this._navigateList(item);
            return;
        }
        const promptModel = { leadId: this.lead.id, dispositions: this.allDispositions, dialerTeam: this.dialerTeam, doNotCall: this.lead.doNotCall, autoDialNextLead: this._autoDialNextLead, nextLeadName: item.name };
        this._dialogService.open({ viewModel: PromptForDisposition, model: promptModel, ignoreTransitions: true }).whenClosed(async(response) => {
            if (response.output.disposition) {
                // set the disposition
                await this.setDisposition(response.output.disposition);
            }
            this._autoDialNextLead = response.output.autoDialNextLead;
            if (response.output.autoDialNextLead) {
                this._autoDialNextLeadAfterLoad = true;
            }
            this._navigateList(item);
        });
    }

    _navigateList(item) {
        this.leadId = item.id;
        this.nav = this._leads.getNavWithNames(this.leadId);
    }

    async leadIdChanged() {
        if (!this._isAttached) return;
        await this._load();
        this._loadHandlers();
        if (this._autoDialNextLeadAfterLoad) {
            await this.autoDial();
            this._autoDialNextLeadAfterLoad = undefined;
        }
    }

    async _load() {
        if (!this.leadId) return;
        try {
            this.loading = true;
            this.displayMapView = this.leadCardSettings.defaultMapView === 'street' ? 'streetview' : 'place';
            this.enlargeAddressMap = undefined;
            this.mapZoom = this.leadCardSettings.defaultMapZoom ?? 15;
            const loaders = [
                this._leads.byId(this.leadId),
                this._members.autoDialTeam(),
            ];
            const data = await Promise.all(loaders);

            this.lead = data[0];
            this.dialerTeam = data[1];
            this.leadStatus = this.lead.status;
            this._element.dispatchEvent(new CustomEvent('loaded', { bubbles: true, detail: { name: this.lead.fullName } }));
            this._loadTabs();
            this._loadRightTabs();
            this._loadDispositionOptions();
            this.viewLeadPdf(true);
        } catch (err) {
            console.log(err);
        } finally {
            this.loading = false;
        }
	}

    _loadTabs() {
        this.tabs = [];
        const tabKeys = this.leadCardSettings.tabOrder ?? ['notes', 'scripts', 'details', 'activity', 'assignments'];
        tabKeys.forEach(tk => {
            if (tk === 'notes') this.tabs.push({ key: 'notes', expanded: !this.leadCardSettings.notesDefaultClosed, title: 'notes', viewModel: PLATFORM.moduleName('./tabs/tab-notes'), model: { minimal: true, leadId: this.lead.id, security: this.security }, display: true, actions: this._defaultClosedActions('notes', 'notesDefaultClosed') });
            if (tk === 'scripts') this.tabs.push({ key: 'scripts', expanded: !this.leadCardSettings.scriptsDefaultClosed, title: 'lead:script', viewModel: PLATFORM.moduleName('./tabs/tab-scripts'), model: this.lead, display: this.security.isInRole(ROLE.LeadScript), actions: this._defaultClosedActions('scripts', 'scriptsDefaultClosed') });
            if (tk === 'details') this.tabs.push({ key: 'details', expanded: !this.leadCardSettings.detailsDefaultClosed, title: 'details', viewModel: PLATFORM.moduleName('./tabs/tab-details'), model: this.lead, display: true, actions: this._defaultClosedActions('details', 'detailsDefaultClosed') });
            if (tk === 'activity') this.tabs.push({ key: 'activity', expanded: !this.leadCardSettings.activityDefaultClosed, title: 'lead:activity', viewModel: PLATFORM.moduleName('./tabs/tab-activity'), model: { lead: this.lead, style: 'timeline' }, display: true, actions: this._defaultClosedActions('activity', 'activityDefaultClosed') });
            if (tk === 'assignments') this.tabs.push({ key: 'assignments', expanded: !this.leadCardSettings.assignmentsDefaultClosed, title: 'lead:assignments', viewModel: PLATFORM.moduleName('./tabs/tab-assignments'), model: this.lead, display: this.security.isInRole(ROLE.LeadManager), actions: this._defaultClosedActions('assignments', 'assignmentsDefaultClosed') });
        });
    }

    _defaultClosedActions(key, settingProperty) {
        const actions = [];
        actions.push({ key: `${key}-default-open`, name: this._i18n.tr('default-open'), icon: 'fa-duotone fa-folder-open', cssClass: this.leadCardSettings[settingProperty] ? '' : 'lpfn-success' });
        actions.push({ key: `${key}-default-closed`, name: this._i18n.tr('default-closed'), icon: 'fa-duotone fa-folder-closed', cssClass: this.leadCardSettings[settingProperty] ? 'lpfn-success' : '' });
        return actions;
    }

    @computedFrom('leadStatus')
    get statusIcon() {
        return LEAD_STATUS.icon(this.leadStatus);
    }

    @computedFrom('lead', 'security')
    get canEdit() {
        if (!this.lead) return false;
        if (this.security.isInRole(ROLE.LeadManager)) return true;
        if (!this.lead.assignedToAgentId) return false;
        return this.security.isAuthenticatedMember(this.lead.assignedToAgentId);

    }

    tabAction(key) {
        try {
            switch (key) {
                case 'notes-default-open':
                    this.leadCardSettings.notesDefaultClosed = false;
                    break;
                case 'notes-default-closed':
                    this.leadCardSettings.notesDefaultClosed = true;
                    break;
                case 'scripts-default-open':
                    this.leadCardSettings.scriptsDefaultClosed = false;
                    break;
                case 'scripts-default-closed':
                    this.leadCardSettings.scriptsDefaultClosed = true;
                    break;
                case 'details-default-open':
                    this.leadCardSettings.detailsDefaultClosed = false;
                    break;
                case 'details-default-closed':
                    this.leadCardSettings.detailsDefaultClosed = true;
                    break;
                case 'activity-default-open':
                    this.leadCardSettings.activityDefaultClosed = false;
                    break;
                case 'activity-default-closed':
                    this.leadCardSettings.activityDefaultClosed = true;
                    break;
                case 'assignments-default-open':
                    this.leadCardSettings.assignmentsDefaultClosed = false;
                    break;
                case 'assignments-default-closed':
                    this.leadCardSettings.assignmentsDefaultClosed = true;
                    break;
            }
            this._saveSettings();
        } catch (err) {
            console.log(err);
        }
    }

    _loadRightTabs() {
        this.rightTabs = [];
        const tabKeys = this.leadCardSettings.rightTabOrder ?? ['reminders', 'map', 'pdfs'];
        tabKeys.forEach(tk => {
            if (tk === 'reminders') this.rightTabs.push({ key: 'reminders', title: 'lead:reminders-appointments', display: true });
            if (tk === 'map') this.rightTabs.push({ key: 'map', title: 'map', display: true });
            if (tk === 'pdfs') this.rightTabs.push({ key: 'pdfs', title: 'pdfs', display: true });
        });
        this.activeRightTab = this.rightTabs[0].key;
    }

    mapAction(key) {
        try {
            switch (key) {
                case 'map-default-street':
                    this.leadCardSettings.defaultMapView = 'street';
                    break;
                case 'map-default-map':
                    this.leadCardSettings.defaultMapView = 'map';
                    break;
                case 'map-default-zoom-plus':
                    this.mapZoom++;
                    this.leadCardSettings.defaultMapZoom = this.mapZoom;
                    break;
                case 'map-default-zoom-minus':
                    this.mapZoom--;
                    this.leadCardSettings.defaultMapZoom = this.mapZoom;
                    break;
            }
            this._saveSettings();
        } catch (err) {
            console.log(err);
        }
    }

    editLead() {
        this._dialogService.open({ viewModel: EditLeadDialog, model: this.lead, ignoreTransitions: true }).whenClosed(response => {
            if (response.wasCancelled) return;
        });
    }

    _loadDispositionOptions() {
        this.allDispositions = this._leads.dispositions(this.lead.status);
    }

    setMapView(view) {
        this.displayMapView = view;
    }

    async viewLeadPdf(initialLoad = false) {
        this.displayInlineFile = undefined;
        const model = { ids: [this.lead.id], inline: true };
        await this._page.export(this._api, 'leads/export/pdf', model, false, (src) => {
            this.displayInlineFile = { initialLoad, src };
        });
    }

    async autoDial() {
        try {
            if (!this.lead || !this.lead.id) return;
            const response = await this._dialer.call([this.lead.id]);
            if (response.succeeded) this._notifier.success('auto-dialer-success');
            else this._notifier.error(response.message);
        } catch (err) {
            console.log(err);
            this._notifier.error('auto-dialer-error');
        }
    }

    addNote() {
        const model = { type: ABOUT_TYPE.Lead, aboutId: this.lead.id, note: undefined };
        this._dialogService.open({ viewModel: EditNote, model, ignoreTransitions: true });
    }

    async setDisposition(d) {
        try {
            const result = await this._leads.setDisposition(this.lead.id, d);
            if (!result.succeeded) {
                this._notifier.error(result.message);
                return;
            }
            this.lead = result.model;
            this.leadStatus = this.lead.status;
            this._loadDispositionOptions();
            this._ea.publish(c.EventKeys.lead.updated, { id: this.lead.id, lead: result.model });
        } catch (err) {
            console.log(err);
        }
    }

    async resetToNew() {
        try {
            const result = await this._leads.resetToNew(this.lead.id);
            if (!result.succeeded) {
                this._notifier.error(result.message);
                return;
            }
            this.lead = result.model;
            this.leadStatus = this.lead.status;
            this._loadDispositionOptions();
            this._ea.publish(c.EventKeys.lead.updated, { id: this.lead.id, lead: result.model });
        } catch (err) {
            console.log(err);
        }
    }

    async removeDoNotCall() {
        try {
            await this._leads.removeDoNotCall(this.lead.id);
            this.lead.doNotCall = false;
            this.lead.doNotCallDate = null;
            this._ea.publish(c.EventKeys.lead.updated, { id: this.lead.id, lead: this.lead });
        } catch (err) {
            console.log(err);
        }
    }

    openMap() {
        this.enlargeAddressMap = new Date().valueOf();
    }

	scrollToTab(tab) {
        if (!this._isAttached) return;
        try {
            this.activeTab = tab.key;
            const scrollToEl = document.getElementById(`tab-${tab.key}`);
            if (!scrollToEl) return;
            LpfnUtil.scrollToElm(this.tabContainerEl, scrollToEl, 600);
        } catch (err) {
            console.log(err);
        }
    }

	scrollToRightTab(tab) {
        if (!this._isAttached) return;
        try {
            this.activeRightTab = tab.key;
            const scrollToEl = document.getElementById(`rightTab-${tab.key}`);
            if (!scrollToEl) return;
            LpfnUtil.scrollToElm(this.rightTabContainerEl, scrollToEl, 600);
        } catch (err) {
            console.log(err);
        }
	}
}
