import { bindable, observable } from 'aurelia-framework';
import { CRM } from 'services/crm';
import { I18n } from 'common/i18n';
import { formatPhone } from 'services/functions';
import { CssAnimator } from 'aurelia-animator-css';
import { TemplatingEngine } from 'aurelia-templating';
import { Dialer, DIALER_TEAM } from 'services/dialer';
import haversine from 'haversine';
import { c } from 'common/common';

export class LeadMap {
    static inject = [CRM, I18n, CssAnimator, TemplatingEngine, Dialer];
    _crm;
    _i18n;
    _animator;
    _templatingEngine;
    _dialer;

    @bindable waitForLoad = false;
    @bindable({ changeHandler: '_reloadData' }) load;
    @bindable({ changeHandler: '_reloadData' }) leads;

    markers;
    @observable visibleMarkers;
    visibleLeadFilters = [{ value: '', keys: ['city', 'email', 'firstName', 'lastName', 'phone', 'state', 'address', 'zipCode', 'county', 'product', 'statusName', 'typeName'] }];
    @observable currentLead;
    selectId;

    constructor(crm, i18n, animator, templatingEngine, dialer) {
        this._crm = crm;
        this._i18n = i18n;
        this._animator = animator;
        this._templatingEngine = templatingEngine;
        this._dialer = dialer;
    }

    attached() {
        this._loadLeads();
    }

    async _loadCanAutoDial() {
        try {
            this.canAutoDial = await this._dialer.canDial(DIALER_TEAM.NoPrompt);
        } catch (err) {
            console.log(err);
        }
    }

    _reloadData() {
        this._loadLeads();
    }

    async _loadLeads() {
        try {
            if (this.waitForLoad && !this.load) return;
            if (!this.leads.length) {
                this.markers = [];
                return;
            }
            const markers = [];
            const anySelected = this.leads.find(x => x.selected) !== undefined;
            this.leads.forEach(l => {
                if (!l.point) return;
                if (anySelected && !l.selected) return;
                markers.push({
                    id: l.id,
                    title: l.fullName,
                    lat: l.point.latitude,
                    lng: l.point.longitude,
                    marker: 'fa-solid fa-user-shield',
                });
            });
            this.markers = markers;
        } catch (err) {
            console.log(err);
        }     
    }

    currentLeadChanged() {
        if (!this.currentLead || !this.visibleLeads) return;
        try {
            this.visibleLeads.forEach(l => {
                l.milesBetween = haversine(this.currentLead, l.point, { unit: 'mile'});
                l.milesBetweenFormatted = c.Helpers.formatNumber(l.milesBetween, 1);
            });
            this.visibleLeads.sort((a, b) => a.milesBetween - b.milesBetween);
        } catch (err) {
            console.log(err);
        }
    }

    visibleMarkersChanged() {
        try {
            if (!this.visibleMarkers) return;
            this.visibleLeads = this.visibleMarkers.map((m, i) => {
                const lead = this.leads.find(x => x.id === m.id);
                if (this.currentLead && lead && lead.point) {
                    lead.milesBetween = haversine(this.currentLead, lead.point, { unit: 'mile'});
                    lead.milesBetweenFormatted = c.Helpers.formatNumber(lead.milesBetween, 1);
                }
                return lead
            });
            this.visibleLeads.sort((a, b) => a.milesBetween - b.milesBetween);
        } catch (err) {
            console.log(err);
        }
    }

    toggleList() {
        this.showList = !this.showList;
        if (this.showList) {
            this._animator.addClass(this.mapListEl, 'lpfn-map-list-show');
        } else {
            this._animator.removeClass(this.mapListEl, 'lpfn-map-list-show');
        }
    }

    popupContent = async(id, el) => {
        try {
            const lead = this.leads.find(x => x.id === id);
            let html = `<div class="lpfn-map-view-popup" id="lpfn-map-view-popup-${id}">`;
            html += `<div class="lpfn-caption">${this._i18n.tr('lead:power-score')}: ${lead.powerScore}</div>`;
            if (lead.phone) html += `<div><a href="tel:${lead.phone}">${formatPhone(lead.phone)}</a></div>`;
            if (lead.email) html += `<div><a href="mailto:${lead.email}">${lead.email}</a></div>`;
            html += `<div class="lpfn-heading"><div>${lead.formattedAddress}</div><div><a href="#" click.delegate="openStreetView('${lead.id}')" class="lpfn-map-view-streetview"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M320 64c0-35.3-28.7-64-64-64s-64 28.7-64 64s28.7 64 64 64s64-28.7 64-64zm-96 96c-35.3 0-64 28.7-64 64v48c0 17.7 14.3 32 32 32h1.8l11.1 99.5c1.8 16.2 15.5 28.5 31.8 28.5h38.7c16.3 0 30-12.3 31.8-28.5L318.2 304H320c17.7 0 32-14.3 32-32V224c0-35.3-28.7-64-64-64H224zM132.3 394.2c13-2.4 21.7-14.9 19.3-27.9s-14.9-21.7-27.9-19.3c-32.4 5.9-60.9 14.2-82 24.8c-10.5 5.3-20.3 11.7-27.8 19.6C6.4 399.5 0 410.5 0 424c0 21.4 15.5 36.1 29.1 45c14.7 9.6 34.3 17.3 56.4 23.4C130.2 504.7 190.4 512 256 512s125.8-7.3 170.4-19.6c22.1-6.1 41.8-13.8 56.4-23.4c13.7-8.9 29.1-23.6 29.1-45c0-13.5-6.4-24.5-14-32.6c-7.5-7.9-17.3-14.3-27.8-19.6c-21-10.6-49.5-18.9-82-24.8c-13-2.4-25.5 6.3-27.9 19.3s6.3 25.5 19.3 27.9c30.2 5.5 53.7 12.8 69 20.5c3.2 1.6 5.8 3.1 7.9 4.5c3.6 2.4 3.6 7.2 0 9.6c-8.8 5.7-23.1 11.8-43 17.3C374.3 457 318.5 464 256 464s-118.3-7-157.7-17.9c-19.9-5.5-34.2-11.6-43-17.3c-3.6-2.4-3.6-7.2 0-9.6c2.1-1.4 4.8-2.9 7.9-4.5c15.3-7.7 38.8-14.9 69-20.5z"/></svg></a></div></div>`;
            html += '</div>';
            return {
                header: lead.fullName,
                body: html,
            };
        } catch (err) {
            console.log(err);
        }
    }

    openStreetView(leadId) {
        const lead = this.leads.find(x => x.id === leadId);
        this.streetView = lead.point;
        if (this.showList) this.toggleList();
    }

    onStreetViewClosed() {
        this.streetView = undefined;
    }

    onMarkerOpened(detail) {
        const popupEl = document.getElementById(`lpfn-map-view-popup-${detail.id}`);
        if (popupEl) {
            this._templatingEngine.enhance({ element: popupEl, bindingContext: this });
        }
        if (this.selectId === detail.id) return;
        const selectedLead = this.leads.find(x => x.id === detail.id);
        if (!this.showList) this.toggleList();
        this.selectLead(selectedLead);
    }

    onMarkerClosed() {
        if (!this.selectId) return;
        this.selectId = undefined;
        this.selectedLead = undefined;
    }

    selectLead(lead) {
        this.selectId = lead.id;
        this.selectedLead = lead;
    }

    clearSelectedItem() {
        this.selectId = undefined;
        this.selectedLead = undefined;
    }

    onMouseenter(lead) {
        this.highlightId = lead.id;
    }

    onMouseleave() {
        this.highlightId = undefined;
    }
}
